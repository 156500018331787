import { useState, useEffect } from 'react'

import debounce from 'lodash/debounce'
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState([0, 0])
  useEffect(() => {
    function onResize () {
      const { innerWidth, innerHeight } = window
      setWindowSize([innerWidth, innerHeight])
    }
    onResize()
    const dOnResize = debounce(onResize, 150)
    window.addEventListener('resize', dOnResize)
    return () => {
      window.removeEventListener('resize', dOnResize)
    }
  }, [])
  return windowSize
}

export default useWindowSize
