
import React, { useState, useEffect } from 'react'
import { Spring, config } from 'react-spring/renderprops'

const ScrollTo = ({ to, onStart, onFrame, onRest }) => {
  const [toState, setToState] = useState(undefined)

  useEffect(() => {
    if (toState !== to) {
      setToState(to)
    }
  }, [to])

  useEffect(() => {
    function onWheel () {
      setToState(undefined)
    }
    window.addEventListener('wheel', onWheel)
    return () => window.removeEventListener('wheel', onWheel)
  }, [])

  function onRestCallback (props) {
    if (typeof onRest === 'function') onRest()
    setToState(undefined)
  }

  return toState
    ? (
      <Spring
        key={to}
        from={{ scrollTop: window.scrollY }}
        to={{ scrollTop: toState }}
        onStart={(props) => (typeof onRest === 'function') && onStart(props)}
        onFrame={(props) => (typeof onFrame === 'function') && onFrame(props)}
        onRest={onRestCallback}
        config={{ clamp: true, ...config.stiff }}
      >
        {springs => {
          if (document) document.scrollingElement.scrollTop = springs.scrollTop
          return null
        }}
      </Spring>
    )
    : null
}

export default ScrollTo
