import React, { useEffect, useState } from 'react'
import { Sled, Views, Progress, Control } from 'react-sled'
import Image from '../image'

const SliderSled = ({ width, work, works, pause, setWorkIndex, color, landscapeView }) => {
  const [auto, setAuto] = useState(2999)
  function onSledEnd () {
    setWorkIndex(workIndex => {
      if (workIndex + 1 === works.length) {
        return workIndex
      }
      return workIndex + 1
    })
  }

  useEffect(() => {
    setTimeout(() => setAuto(3000), 50)
  }, [])
  return (
    <Sled>
      <Views
        width={`${width}px`}
        height='100ow'
        autoPlay={auto}
        pause={pause}
        dragging={false}
        pauseOnMouseOver={false}
        onSledEnd={onSledEnd}
        style={`
          order: ${landscapeView ? 1 : 2}

          :focus,
          .sled-view:focus {
              outline: none;
              box-shadow: none;
          }
          .sled-view {
              display: flex;
              align-items: center;  
              padding: 0 0 ${landscapeView ? '0' : '15vw'} 0;
          }
        `}
      >
        {work.contentWork.map(image => (
          <Image
            key={image.url}
            className='archive__image'
            sizes={image.sizes}
            styleImg={`
            width: 100%;
              object-fit: contain;
            `}
            style={`
              width: 100%;
              height: 100%;
              padding-top: 0;
              pointer-events: none;
              // background: rgba(0,0,0,0.1);
            `}
          />
        ))}
      </Views>
      <div className='archive__control-wrapper'>
        <Control goto={'prev'} style={`width: 50%;`} />
        <Control goto={'next'} style={`width: 50%;`} />
      </div>
      <Progress style={`
        z-index: 100000;
        order: ${landscapeView ? 2 : 1}
        .sled-progress-rail {
          background: white;
        }
        .sled-progress-track {
          background: black;
        }
        .sled-progress-separator {
          transition: 0.5s;
          background: ${color};
        }
        .sled-control:focus,
        .sled-control:active {
          background: none;
        }
      `} />
    </Sled>
  )
}

export default SliderSled
