import React from 'react'
import { Helmet } from 'react-helmet'
// import PropTypes from 'prop-types'
// import { StaticQuery, graphql } from 'gatsby'

const baseUrl = 'https://lisaweiss.ch'

const SEO = ({
  title, description, url, image
}) => (
  <Helmet title={title}>
    <link rel='canonical' href={baseUrl + url} />
    <meta name='description' content={description} />
    <meta name='image' content={image} />
    {url && <meta property='og:url' content={baseUrl + url} />}
    {title && <meta property='og:title' content={title} />}
    {description && <meta property='og:description' content={description} />}
    {/* {image && <meta property='og:image' content={image} />} */}
    <meta name='twitter:card' content='summary_large_image' />
    {/* {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />} */}
    {title && <meta name='twitter:title' content={title} />}
    {description && <meta name='twitter:description' content={description} />}
    {/* {image && <meta name='twitter:image' content={image} />} */}
  </Helmet>
)

export default SEO

// const query = graphql`
//   query SEO {
//     site {
//       siteMetadata {
//         defaultTitle: title
//         defaultDescription: description
//         siteUrl: url
//         defaultImage: image
//       }
//     }
//   }
// `
