
import { useEffect } from 'react'

const useIntersectionObserver = ({ target, root, onIntersect, threshold = 1.0, rootMargin = '0px' }) => {
  useEffect(() => {
    if (!window.IntersectionObserver) return
    const observer = new IntersectionObserver(onIntersect, {
      root: null,
      rootMargin,
      threshold
    })
    observer.observe(target.current)
    return () => {
      observer.unobserve(target.current)
    }
  }, [root, target])
}

export default useIntersectionObserver
