import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { useTransition, useSpring, animated } from 'react-spring'

import useWindowSize from 'utils/useWindowSize'
import useBackgroundColor from 'utils/useBackgroundColor'
import useLandscapeView from 'utils/useLandscapeView'
import { useTransitionStore } from 'gatsby-plugin-transitions'
import SliderSled from './sled'

const SliderWrapper = ({ work, works, pause, setWorkIndex, location }) => {
  const [width, setWidth] = useState(0)
  const [windowWidth, windowHeight] = useWindowSize()
  const landscapeView = useLandscapeView(windowWidth, windowHeight)
  const [{ currentLocation }] = useTransitionStore()

  const [springProps, setSpring] = useSpring(() => ({
    from: { opacity: 0 },
    opacity: 1,
    delay: 1000
  }))
  const [hide, setHide] = useState(false)
  useEffect(() => {
    if (currentLocation.pathname !== location.pathname) {
      setSpring({
        opacity: 0,
        delay: 0
      })
      setHide(true)
    }
  }, [currentLocation.pathname, location.pathname])

  const bgColor = useBackgroundColor(currentLocation)

  useEffect(() => {
    if (landscapeView) {
      const vw4 = windowWidth * 0.04
      const width = (windowWidth - vw4) / 3
      setWidth(width)
    } else {
      setWidth(windowWidth * 0.96)
    }
  }, [windowWidth, landscapeView])

  const transitions = useTransition(work, item => item.url, {
    from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    unique: true
  })
  return (
    <animated.div
      className={`slider-wrapper ${hide ? 'slider-wrapper--hide' : ''}`}
      style={{
        position: 'fixed',
        zIndex: '500',
        top: 0,
        paddingTop: landscapeView ? '8vw' : '8.75vw',
        width,
        height: !landscapeView && windowWidth * 1.0875,
        '--slider-bg': bgColor,
        opacity: springProps.opacity
      }}
    >
      {transitions.map(({ item, props, key }) => item.url && (
        <animated.div
          key={item.url}
          style={{
            position: 'absolute',
            left: 0,
            paddingLeft: landscapeView ? '1vw' : '2vw',
            display: 'flex',
            flexWrap: 'wrap',
            ...props
          }}
        >
          <SliderSled
            work={item}
            width={width}
            color={bgColor}
            setWorkIndex={setWorkIndex}
            works={works}
            pause={pause}
            landscapeView={landscapeView}
          />
          {landscapeView && (
            <div
              className='size--0 slider__text'
              style={{ order: 3 }}
              dangerouslySetInnerHTML={{ __html: item.text }}
            />
          )}
        </animated.div>
      ))}
    </animated.div>
  )
}

const Slider = (props) => {
  const [container, setContainer] = useState(null)
  useEffect(() => {
    setContainer(document.getElementById('___gatsby'))
  }, [])

  return container && ReactDOM.createPortal(
    <SliderWrapper {...props} />,
    container
  )
}

export default Slider
