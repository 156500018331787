import { useState, useEffect } from 'react'

const useLandscapeView = (windowWidth, windowHeight) => {
  const [landscapeView, setLandscapeView] = useState(true)
  useEffect(() => {
    setLandscapeView(windowWidth > windowHeight)
  }, [windowWidth])
  return landscapeView
}

export default useLandscapeView
