import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useObservers from './hooks/useObservers'
import { StyledDiv, StyledImg } from './styled'
import { getSrcSet, getPaddingTop } from './utils'

const Image = ({
  url,
  title,
  className,
  sizes,
  ratio,
  width,
  height,
  modified,
  alt,
  style,
  styleImg
}) => {
  const [srcSet] = useState(() => getSrcSet(sizes, modified))
  const [paddingTop] = useState(() => getPaddingTop(ratio, width, height))
  const [loaded, setloaded] = useState(false)
  const targetRef = useRef()
  const [load] = useObservers(targetRef, '800px')
  function onLoaded () {
    setloaded(true)
  }
  return (
    <StyledDiv
      ref={targetRef}
      className={classNames(className, {
        loaded
      })}
      paddingTop={paddingTop}
      styles={style}
    >
      <StyledImg
        srcSet={load
          ? srcSet
          : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
        src={load
          ? url
          : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
        }
        alt={alt}
        title={title}
        styles={styleImg}
        onLoad={onLoaded}
      />
    </StyledDiv>
  )
}

Image.propTypes = {
  sizes: PropTypes.array,
  ratio: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  modified: PropTypes.number,
  style: PropTypes.string,
  styleImg: PropTypes.string,
  alt: PropTypes.string
}

Image.defaultProps = {
  sizes: [],
  ratio: '1:2',
  alt: 'Sorry, there is no ALT-Text for this image yet.',
  width: undefined,
  height: undefined,
  modified: undefined,
  style: '',
  styleImg: `
    object-fit: cover;
    object-position: center;
  `
}

export default Image
