import React from 'react'
import { graphql } from 'gatsby'
import ArchiveBody from 'components/archive'
import SEO from '../seo'

const Archive = ({ data, location }) => {
  const { nodes: archives } = data.allArchive
  return (
    <>
      <SEO
        title={'Archive | ' + data.seo.title}
        description={data.seo.description}
        url={location.url}
      />
      <ArchiveBody
        archives={archives}
        location={location}
      />
    </>
  )
}

export default Archive

export const query = graphql`
  query {
    seo {
      title
      description
    }
    allArchive {
      nodes {
        url
        title
        text
        description
        contentWork {
          type
          videoDesktop
          videoMobile
          url
          alt
          sizes
          modified
          # local {
          #   childImageSharp {
          #     fluid(maxWidth: 1200) {
          #       ...GatsbyImageSharpFluid_withWebp_noBase64
          #     }
          #   }
          # }
        }
      }
    }
  }
`
