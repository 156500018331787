import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import useWindowSize from 'utils/useWindowSize'
import useLandscapeView from 'utils/useLandscapeView'
import useBackgroundColor from 'utils/useBackgroundColor'
import { useTransitionStore } from 'gatsby-plugin-transitions'
import { useSpring, animated } from 'react-spring'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const ArchiveInfo = ({ work, closeInfo }) => {
  const [{ currentLocation }] = useTransitionStore()
  const bgColor = useBackgroundColor(currentLocation)
  const ref = useRef()

  useEffect(() => {
    ref.current.focus()
    disableBodyScroll(ref.current)
    return () => enableBodyScroll(ref.current)
  }, [])

  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 }
  })
  return (
    <animated.div
      ref={ref}
      className='archive__info'
      style={{
        opacity: props.opacity,
        '--main-bg-color': bgColor
      }}
    >
      <p
        className='size--1'
        dangerouslySetInnerHTML={{ __html: work.text }}
      />
      <div
        className='archive__info__close size--1'
        onClick={closeInfo}
      >
        Close
      </div>
    </animated.div>
  )
}

function setToFunc ({ landscapeView, y, rectTop, windowHeight, windowWidth }) {
  if (landscapeView) {
    return y + (rectTop - (windowHeight * 0.5)) + (windowWidth * 0.02)
  } else {
    if (windowWidth < 1025) {
      return y + (rectTop - (windowWidth * 1.1))
    } else {
      return y + (rectTop - (windowWidth * 1.1))
    }
  }
}

const ArchiveHeadline = ({
  index,
  node,
  setWorkIndex,
  workIndex,
  setTo,
  setPause
}) => {
  const ref = useRef()
  const [isActive, setActive] = useState(false)
  const [windowWidth, windowHeight] = useWindowSize()
  const landscapeView = useLandscapeView(windowWidth, windowHeight)

  function scrollToElement () {
    const rectTop = ref.current.getBoundingClientRect().top
    const y = window.scrollY
    setTo(setToFunc({ landscapeView, y, rectTop, windowHeight, windowWidth }))
  }
  useEffect(() => {
    if (workIndex && workIndex === index) {
      scrollToElement()
    }
  }, [workIndex])

  const [initial, setInitial] = useState(true)
  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      setInitial(false)
    }, 150)
    return () => window.clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    if (initial) return

    let line = 0
    if (landscapeView) {
      line = (windowHeight * 0.5) + (windowWidth * 0.01)
    } else {
      if (windowWidth < 1025) {
        line = (windowWidth * 1.15)
      } else {
        line = (windowWidth * 1.15)
      }
    }

    let timeoutId

    function onScroll () {
      const rect = ref.current.getBoundingClientRect()
      if (timeoutId) window.clearTimeout(timeoutId)
      if (rect.top < line && rect.top + rect.height > line) {
        setActive(true)
        timeoutId = window.setTimeout(() => {
          setWorkIndex(index)
        }, 100)
      } else {
        setActive(false)
      }
    }
    onScroll()
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
      if (timeoutId) window.clearTimeout(timeoutId)
    }
  }, [initial, landscapeView, windowHeight])

  const [modalVisible, setModalVisible] = useState(false)
  function toggleInfo (toggle) {
    if (toggle) {
      setModalVisible(true)
      setPause(true)
    } else {
      setModalVisible(false)
      setPause(false)
    }
  }
  const [container, setContainer] = useState(null)
  useEffect(() => {
    setContainer(document.getElementById('___gatsby'))
  }, [])

  return (
    <li
      className='archive__li'
      key={node.url}
      ref={ref}
    >
      <h2
        className={`archive__headline archive__headline--${isActive && 'active'} size--1`}
        onClick={scrollToElement}
      >
        {node.title}
      </h2>
      {!landscapeView && isActive && (
        <button
          className='archive__headline__info size--1'
          onClick={() => toggleInfo(true)}
        >
          Info
        </button>
      )}
      {modalVisible && ReactDOM.createPortal(
        <ArchiveInfo
          work={node}
          closeInfo={() => toggleInfo(false)}
        />,
        container
      )}
    </li>
  )
}

export default ArchiveHeadline
