import { useEffect, useState } from 'react'
import useIntersectionObserver from './useIntersectionObserver'

const useObservers = (targetRef, distance = '500px') => {
  const [load, setload] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useIntersectionObserver({
    root: null,
    target: targetRef,
    rootMargin: '500px',
    threshold: 0.0,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting) {
        setload(true)
      }
    }
  })
  useIntersectionObserver({
    root: null,
    target: targetRef,
    rootMargin: '0px',
    threshold: 0.0,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }
  })

  // fallback!
  useEffect(() => {
    if (!window.InersectionObserver) {
      setload(true)
      setIsVisible(true)
    }
  }, [])

  return [load, isVisible]
}

export default useObservers
