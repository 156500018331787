import React, { useState, useEffect } from 'react'
import Slider from 'components/archive/slider'
import gridProps from 'layouts/gridProps'
import { Grid, Box } from 'react-raster'

import ArchiveHeadline from './headline'
import useWindowSize from 'utils/useWindowSize'
import useLandscapeView from 'utils/useLandscapeView'

import ScrollTo from './scrollTo'
import { useTransitionStore } from 'gatsby-plugin-transitions'

const ArchiveBody = ({ archives, location }) => {
  const [workIndex, setWorkIndex] = useState(0)
  const [work, setWork] = useState(archives[workIndex])
  const [to, setTo] = useState(false)
  const [pause, setPause] = useState(false)
  const [{ currentLocation }] = useTransitionStore()

  const [windowWidth, windowHeight] = useWindowSize()
  const landscapeView = useLandscapeView(windowWidth, windowHeight)

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      setWork(archives[workIndex])
    }, 100)
    return () => { window.clearTimeout(timeoutId) }
  }, [workIndex])

  useEffect(() => {
    if (currentLocation.pathname !== location.pathname) {
      setWork({ url: undefined })
    }
  }, [currentLocation.pathname, location.pathname])

  const [top, settop] = useState(undefined)
  const [bottom, setbottom] = useState(undefined)
  useEffect(() => {
    if (landscapeView) {
      settop('calc(50vh - 10px)')
      setbottom('calc(50vh - 2vw)')
    } else {
      settop('108vw')
      setbottom('calc(100vh - 118vw)')
    }
  }, [landscapeView, windowWidth])
  // return typeof workIndex === 'number' && (
  return (
    <div className='archive'>
      <ScrollTo to={to} />
      <Slider
        work={work}
        works={archives}
        setWorkIndex={setWorkIndex}
        pause={pause}
        location={location}
      />
      {top && (
        <Grid
          {...gridProps}
          top={top}
          bottom={bottom}
          styleOuter={`
            min-height: 100vh;
            color: #fff;
          `}
          control={process.env.NODE_ENV === 'development'}
        >
          <Box
            cols={landscapeView ? 7 : 12}
            left={landscapeView ? 5 : 0}
          >
            <ul className='archive__ul'>
              {archives.map((node, index) => (
                <ArchiveHeadline
                  key={node.title}
                  workIndex={workIndex}
                  index={index}
                  node={node}
                  setWorkIndex={setWorkIndex}
                  setTo={setTo}
                  setPause={setPause}
                />
              ))}
            </ul>
          </Box>
        </Grid>
      )}
    </div>
  )
}

export default ArchiveBody
