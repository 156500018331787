function getRatio (ratio) {
  const ratioArray = ratio.split(':')
  return (ratioArray[0] / ratioArray[1]) * 100
}

function getNaturalRatio (width, height) {
  return (height / width) * 100
}

export function getPaddingTop (ratio, width, height) {
  if (width && height) return getNaturalRatio(width, height)
  return getRatio(ratio)
}

export function getSrcSet (sizes, modified) {
  // console.log(sizes)
  if (!sizes) return ''
  return sizes
    .map((size) => {
      const sizeNumberString = size.match(/\d+x0/)
      if (!sizeNumberString) return ''
      const sizeNumber = sizeNumberString[0].replace('x0', '')
      const mod = `?v=${modified}`
      return `${size + mod} ${sizeNumber}w`
    })
    .filter(size => size)
    .join(', ')
}
