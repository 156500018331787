import styled from 'styled-components'

export const StyledDiv = styled.div`
  width: 100%;
  height: 0;
  overflow: hidden;
  position: relative;
  padding-top: ${props => props.paddingTop}%;
  ${props => props.styles}
`

export const StyledImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${props => props.styles}
`
